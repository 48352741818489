<template>
  <!-- TODO: a11y - Accordion completely inaccessible, make a11y accordion component -->
  <div class="mb-4">
    <div>
      <a
        class="cursor-pointer flex justify-between items-center mb-2"
        @click="toggle"
      >
        <h5 class="inline text-sm text-gray-700">
          {{ label }}
        </h5>
        <Icon
          name="IconChevronDown"
          class="transform transition-all duration-200"
          :class="showItems ? 'rotate-180' : ''"
          :title="showItems ? 'hide requirements' : 'show requirements'"
        />
        <span class="sr-only" aria-live="polite">{{
          showItems ? 'hide requirements' : 'show requirements'
        }}</span>
      </a>

      <BrandListGroup
        v-if="items.length"
        :aria-expanded="showItems"
        :items="items"
        :color="color"
        :class="[showItems ? 'block' : 'hidden']"
        class="text-xs"
      >
      </BrandListGroup>
      <BrandList v-else>
        <BrandListItem
          :aria-expanded="showItems"
          :color="color"
          :class="[showItems ? 'block' : 'hidden']"
          class="text-xs"
        >
          <template v-if="label === 'Entry requirements'"
            >No entry requirements for this course</template
          >
          <template v-if="label === 'Career pathways'"
            >No career pathways defined for this course</template
          >
        </BrandListItem>
      </BrandList>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseCardAccordion',
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    items: {
      type: [Array],
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showItems: false
  }),
  methods: {
    toggle() {
      this.showItems = !this.showItems
    }
  }
}
</script>
